@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');



html, *{
  padding:0;
  margin:0;
  font-family: sen;

}

::-webkit-scrollbar{
  width:0; 
  height:0;
}

body {
  scroll-behavior: smooth;
  @apply m-0 bg-white
}

.inputs {
  @apply bg-gray-300 p-2 border border-gray-200  outline-none w-full m-2
}

input:focus, textarea:focus, select:focus{
  @apply outline-none
}
select{
  @apply w-full p-1 text-sm border border-gray-200 bg-gray-50 outline-none 
}

.btn{
  @apply p-2 bg-gray-500 text-white mx-auto text-xs w-[120px] rounded-sm
}

.slide-in{
  animation: slide-in 50ms ease-in-out 0s 1 forwards normal;
}

.slide-out{
  animation: slide-out 50ms ease-in-out 0s 1 forwards alternate;
}

.slide-up{
  animation: slide-up 60ms ease-in 20ms 1 forwards alternate;
}

@keyframes slide-in {
  from{
    width: 180px; 
  }
  to{
    width: 80px;
  }
}

.react-calendar {
  border: none !important;
}
@keyframes slide-out {
  from{
    width: 100px; 
  }
  to{
    width: 180px;
  }
}

@media screen and (max-width:500px) {
  @keyframes slide-in {
    from{
      width: 80px; 
    }
    to{
      width: 100px;
    }
  }
  
  .slide-in{
    animation: slide-in 50ms ease-in-out 0s 1 forwards normal;
  }
  
  .slide-out{
    animation: slide-out 50ms ease-in-out 0s 1 forwards alternate;
  }

  .ql-container {
    border: none !important;
  }

  @keyframes slide-out {
    from{
      width: 0;
    }
    to{
      width: 180px;
    }
  }
}

@keyframes slide-up {
  from{
    @apply -bottom-10 -right-10
  }

  to{
    @apply bottom-5 right-4
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
